import React from "react"
import styled from 'styled-components';
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  InnerContainer,
  Spacer,
  Column,
  Grid,
} from 'components/ui';
import sc from 'config/theme';
import $ from 'jquery';
import { Script, Sans, Serif } from 'Typography';
import bp from 'config/responsive';

const Container = styled.div`
  background: ${sc.colors.white};
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;


  ${bp.computer} {
    padding-top: 200px;
    padding-bottom: 100px;
    min-height: 70vh;
  }

`

const StyledGrid = styled(Grid)`
  &&& {
    padding-top: 50px;
    margin-bottom: 30px;

    ${bp.tablet} {
      margin-bottom: 100px;
    }

    ${bp.computer} {
      padding-top: 0;
      margin-bottom: 100px;
    }
  }
`;

const Title = styled.div`
  margin-bottom: 20px;

  ${bp.computer} {
    margin-bottom: 20px;
  }
`;

const Paragraph = styled.p`
  display: block;

`;

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => (
  browser && (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <InnerContainer>
        <StyledGrid>
          <Spacer />
          <Column width={10}>
            <div>
              <Title class="">
                <Serif alpha color="main" block align="center">Not found</Serif>
              </Title>
              <Sans alpha color="gray">
                <Paragraph>The page you are looking for does not exist.</Paragraph>
              </Sans>
            </div>
          </Column>
          <Spacer />
        </StyledGrid>

      </InnerContainer>
    </Container>

  </Layout>
  )
)

export default NotFoundPage
